
import { defineComponent, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { Actions } from "../../store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import router from "../.././router";
import * as Yup from "yup";
import { ElNotification } from "element-plus";
import store from "@/store";
import loading from "vue";
export default defineComponent({
  name: "sign-in",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  data() {
    return {
      loading: false,
      email: "",
      password: "",
      show: false,
    };
  },

  setup() {
    const store = useStore();
    const router = useRouter();

    const submitButton = ref<HTMLElement | null>(null);

    //Create form validation object
    const login = Yup.object().shape({
      email: Yup.string()
        .email("Email should contain @ and . or invalid email address")
        .required()
        .label("Email")
        .trim(),
      password: Yup.string().min(8).required().label("Password"),
    });

    //Form submit function
    const onSubmitLogin = (values) => {
      // Clear existing errors
      store.dispatch(Actions.LOGOUT);

      if (submitButton.value) {
        // Activate indicator
        submitButton.value.setAttribute("data-kt-indicator", "on");
      }

      // Dummy delay
      setTimeout(() => {
        // Send login request

        store
          .dispatch(Actions.LOGIN, values)
          .then(() => {
            // store.getters.isAuthenticated = true;
            // Swal.fire({
            //     text: "You have logged in successfully!!",
            //     icon: "success",
            //     buttonsStyling: false,
            //     confirmButtonText: "Ok, got it!",
            //     customClass: {
            //       confirmButton: "btn fw-bold btn-light-primary",
            //     },

            // Swal.fire({
            //   text: "All is cool! Now you submit this form",
            //   icon: "success",
            //   buttonsStyling: false,
            //   confirmButtonText: "Ok, got it!",
            //   customClass: {
            //     confirmButton: "btn fw-bold btn-light-primary",
            //   },
            // }).then(function () {
            // Go to page after successfully login
            router.push({ name: "dashboard" });

            // });
          })
          .catch((error) => {
            if (error.response.status == 401) {
              router.push({ name: "sign-in" });
            }
            ElNotification({
              title: "Error",
              message: store.getters.getErrors[0],
              type: "error",
              duration: 2000,
              position: "top-right",
            });
          });

        //Deactivate indicator
        submitButton.value?.removeAttribute("data-kt-indicator");
      }, 20);
    };

    return {
      onSubmitLogin,
      login,
      submitButton,
    };
  },
  methods: {
    // trim(){
    //    let str=this.email.replace(/^\s+|\s+$/g, '')
    //     console.log("fdsf",str,"fdsf")
    // },
    showPassword() {
      this.show = !this.show;
    },
    async onSubmitlogin() {
      // let values = {email: this.email,password:this.password}
      this.loading = true;
      store
        .dispatch(Actions.LOGIN, { email: this.email, password: this.password })
        .then(() => {
          this.loading = false;
          router.push({ name: "dashboard" });
          ElNotification({
            title: "Success",
            message: "Login Successful!",
            type: "success",
            duration: 2000,
            position: "top-right",
          });
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
  },
});
